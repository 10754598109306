
import { dbObject } from "@/types";
import { getElementDescription } from "@/utils/models";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    parent: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const elements = computed(() => store.getters["data/get" + props.parent + "s"]);
    const route = useRoute();
    const setViaRoute = ref(false)

    onMounted(() => {
      console.log(props.parent + '_id')
      if (route.query[props.parent + '_id']) {
        emit('update:modelValue', route.query[props.parent + '_id'])
        setViaRoute.value = true
      }
    })

    function getDescription(el: dbObject) {
      return getElementDescription(el, store);
    }

    return {
      elements,
      getDescription,
      setViaRoute
    }
  }
});
