
import { removeToken } from "@/bootstrap/state";
import { dbObject } from "@/types";
import { getTranslationValue } from "@/utils/locales";
import { getElementDescription, getElementLink } from "@/utils/models";
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()

    const searchText = ref('')
    const selectedKey = ref(0)
    const searchFieldOpen = ref(false)
    const searchResults = computed(() => {
      if (searchText.value) {
        const results = store.getters['data/search'](searchText.value)

        return results
      }

      return []
    })

    function goTo(element: dbObject) {
      const link = getElementLink(element)

      if (link) {
        router.push(link)
      }
    }

    function onKeyUp(e: KeyboardEvent) {
      if (e.key === 'ArrowUp') {
        if (selectedKey.value > 0) {
          selectedKey.value = selectedKey.value - 1
        }

        e.preventDefault()
        e.stopPropagation()
        return false
      }

      if (e.key === 'ArrowDown') {
        if (selectedKey.value < 8 && selectedKey.value < (searchResults.value.length - 1)) {
          selectedKey.value = selectedKey.value + 1
        }

        e.preventDefault()
        e.stopPropagation()
        return false
      }

      if (e.key === "Enter") {
        goTo(searchResults.value[selectedKey.value].model)
      }
    }

    function getTypeName(typename: string) {
      

      return getTranslationValue(typename.slice(0, -1) + ".name")
    }

    function getSearchResultName(element: dbObject) {
      return getElementDescription(element, store)
    }

    const username = computed(() => {
      return store.state.user.firstname + ' ' + store.state.user.lastname
    })

    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })

    const currentDigsite = computed(() => {
      const d = store.getters['data/getCurrentDigsite']

      return d ? d.name : 'Keine Grabung gewählt'
    })

    const queueLength = computed(() => {
      return store.getters['sync/queueLength']
    })

    function logout() {
      removeToken()
      router.push('/account/login')
    }

    return {
      username,
      currentDigsite,
      logout,
      queueLength,
      isOnline,
      searchText,
      searchResults,
      getSearchResultName,
      selectedKey,
      onKeyUp,
      goTo,
      searchFieldOpen,
      getTypeName
    }
  }
});
