
import { Probe } from "@/types.gen";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const positions = computed<Probe[]>(() => store.getters["data/getProbes"]);

    const {
      setSort,
      sortedData
    } = useSort<Probe>(positions, 'probenumber')

    function onClick(position: Probe) {
      router.push("/probe/" + position.id);
    }

    return {
      positions,
      onClick,
      formatDate,
      formatDateTime,
      sortedData
    };
  },
});
