import axios, { AxiosInstance } from "axios";
import { useToken } from "./state";

let http: AxiosInstance

export function setupAxios() {
    const apiPrefix = 'api'
    const token = useToken()

    http = axios.create({
        baseURL: process.env.VUE_APP_SERVER,
        timeout: 5000,
    })

    http.interceptors.request.use(async config => {
        // @ts-ignore
        config.headers['Authorization'] = 'Bearer ' + token.value

        return config
    })

    http.interceptors.response.use((response) => response, (error) => {
        // whatever you want to do with the error
        if ('message' in error) {
            const message = error.message as string

            if (message.indexOf('500') !== -1) {
                alert("Da ist was mit dem Server schiefgegangen: " + message + "\n\nBitte lade die Applikation neu und versuch es nocheinmal.")
            }

            console.log(error.message)
        }
    });
}


export { http }