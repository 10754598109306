import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_date = _resolveComponent("form-date")!
  const _component_form_number = _resolveComponent("form-number")!
  const _component_form_checkbox = _resolveComponent("form-checkbox")!
  const _component_form_relation_up = _resolveComponent("form-relation-up")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (type, field) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: field }, [
        (type === 'string')
          ? (_openBlock(), _createBlock(_component_form_input, {
              key: 0,
              name: field,
              label: _ctx.$t(`${_ctx.typeName}.Fields.${field}`),
              modelValue: _ctx.copyValues[field],
              "onUpdate:modelValue": ($event: any) => ((_ctx.copyValues[field]) = $event),
              layout: "horizontal"
            }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue"]))
          : (type === 'date')
            ? (_openBlock(), _createBlock(_component_form_date, {
                key: 1,
                name: field,
                label: _ctx.$t(`${_ctx.typeName}.Fields.${field}`),
                modelValue: _ctx.copyValues[field],
                "onUpdate:modelValue": ($event: any) => ((_ctx.copyValues[field]) = $event),
                layout: "horizontal"
              }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue"]))
            : (type === 'number')
              ? (_openBlock(), _createBlock(_component_form_number, {
                  key: 2,
                  name: field,
                  label: _ctx.$t(`${_ctx.typeName}.Fields.${field}`),
                  modelValue: _ctx.copyValues[field],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.copyValues[field]) = $event),
                  layout: "horizontal"
                }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue"]))
              : (type === 'boolean')
                ? (_openBlock(), _createBlock(_component_form_checkbox, {
                    key: 3,
                    name: field,
                    label: _ctx.$t(`${_ctx.typeName}.Fields.${field}`),
                    modelValue: _ctx.copyValues[field],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.copyValues[field]) = $event)
                  }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue"]))
                : (type.way === 'up')
                  ? (_openBlock(), _createBlock(_component_form_relation_up, {
                      key: 4,
                      name: field,
                      label: _ctx.$t(`${_ctx.typeName}.Fields.${field}`),
                      modelValue: _ctx.copyValues[field],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.copyValues[field]) = $event),
                      layout: "horizontal",
                      parent: type.to
                    }, null, 8, ["name", "label", "modelValue", "onUpdate:modelValue", "parent"]))
                  : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}