import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.element)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.getLink(_ctx.element),
        href: "#",
        class: "list-group-item list-group-item-action"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Gehört zu " + _toDisplayString(_ctx.$t(`${_ctx.typeName}.name`)) + ": " + _toDisplayString(_ctx.getDescription(_ctx.element)), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}