import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "form-check" }
const _hoisted_3 = ["id", "checked"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        class: "form-check-input",
        id: _ctx.name,
        checked: _ctx.modelValue == 1,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value ? 1 : 0)))
      }, null, 40, _hoisted_3),
      _createElementVNode("label", {
        for: _ctx.name,
        class: "form-check-label"
      }, _toDisplayString(_ctx.label), 9, _hoisted_4)
    ])
  ]))
}