
import { Foundsite } from "@/types.gen";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const foundsites = computed<Foundsite[]>(() => store.getters["data/getFoundsites"]);

    const {
      setSort,
      sortedData
    } = useSort<Foundsite>(foundsites, 'name')

    function onClick(sector: Foundsite) {
      router.push("/foundsite/" + sector.id);
    }

    function exportData() {
      console.log(foundsites.value)
    }

    return {
      exportData,
      foundsites,
      onClick,
      formatDate,
      formatDateTime,
      sortedData
    };
  },
});
