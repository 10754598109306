import { getDigsites, getDynamic, getPositions, getSectors, httpException, ping } from "@/api/requests"
import { dbObject } from "@/types";
import { Digsite, Sector, Position, BuildFinding, FoundObject, Sample, Probe, Core, MeasurementData, Foundsite, Data } from "@/types.gen"
import { Action } from "vuex"
import { State } from "."

export interface SyncState {
    lastSync: Date|null;
    isInSync: boolean;
    syncError: httpException|null;
    syncQueue: {
        digsites: Digsite[],
        sectors: Sector[],
        positions: Position[],
        buildfindings: BuildFinding[],
        foundobjects: FoundObject[],
        samples: Sample[],
        probes: Probe[],
        cores: Core[],
        measurementdatas: MeasurementData[],
        foundsites: Foundsite[]
    };
    isOnline: boolean;
}

const data_key = "APP_SYNC_DATA"
const savedDataString = window.localStorage.getItem(data_key);

let state: SyncState = {
    lastSync: null,
    isInSync: false,
    syncError: null,
    syncQueue: {
        digsites: [],
        sectors: [],
        positions: [],
        buildfindings: [],
        foundobjects: [],
        samples: [],
        probes: [],
        cores: [],
        measurementdatas: [],
        foundsites: []
    },
    isOnline: false
}



if (savedDataString) {
    state = JSON.parse(savedDataString) as SyncState   
}

const getters = {
    queueLength: (state: SyncState) => {
        return state.syncQueue.digsites.length +
            state.syncQueue.sectors.length +
            state.syncQueue.positions.length +
            state.syncQueue.buildfindings.length +
            state.syncQueue.foundobjects.length +
            state.syncQueue.samples.length +
            state.syncQueue.probes.length +
            state.syncQueue.cores.length +
            state.syncQueue.measurementdatas.length +
            state.syncQueue.foundsites.length
    },
    queue: (state: SyncState) => {
        return state.syncQueue
    },
    lastSync: (state: SyncState) => {
        return state.lastSync
    },
    isOnline: (state: SyncState) => {
        return state.isOnline
    }
}

const actions: Record<string, Action<SyncState, State>> = {
    sync: async ({ state, commit, getters }) => {
        const digsites = await getDigsites()

        if ("status_code" in digsites) {
            state.syncError = digsites
            return;
        }
        else {
            commit('data/setDigsites', digsites, { root: true })
        }

        const foundsites = await getDynamic<Foundsite>('foundsite');

        if ("status_code" in foundsites) {
            state.syncError = foundsites
            return;
        }
        else {
            commit('data/setFoundsites', foundsites, { root: true })
        }

        const sectors = await getSectors()

        if ("status_code" in sectors) {
            state.syncError = sectors
            return;
        }
        else {
            commit('data/setSectors', sectors, { root: true })
        }

        const positions = await getPositions();

        if ("status_code" in positions) {
            state.syncError = positions
            return;
        }
        else {
            commit('data/setPositions', positions, { root: true })
        }

        const buildfindings = await getDynamic<BuildFinding>('buildfindings');

        if ("status_code" in buildfindings) {
            state.syncError = buildfindings
            return;
        }
        else {
            commit('data/setBuildFinding', buildfindings, { root: true })
        }

        const foundobject = await getDynamic<FoundObject>('foundobject');

        if ("status_code" in foundobject) {
            state.syncError = foundobject
            return;
        }
        else {
            commit('data/setFoundObject', foundobject, { root: true })
        }

        const Sample = await getDynamic<Sample>('sample');

        if ("status_code" in Sample) {
            state.syncError = Sample
            return;
        }
        else {
            commit('data/setSample', Sample, { root: true })
        }

        const Probe = await getDynamic<Probe>('probe');

        if ("status_code" in Probe) {
            state.syncError = Probe
            return;
        }
        else {
            commit('data/setProbe', Probe, { root: true })
        }

        const Core = await getDynamic<Core>('core');

        if ("status_code" in Core) {
            state.syncError = Core
            return;
        }
        else {
            commit('data/setCore', Core, { root: true })
        }

        const MeasurementData = await getDynamic<MeasurementData>('measurementdata');

        if ("status_code" in MeasurementData) {
            state.syncError = MeasurementData
            return;
        }
        else {
            commit('data/setMeasurementData', MeasurementData, { root: true })
        }


        const data = await getDynamic<Data>('data');

        if ("status_code" in data) {
            state.syncError = data
            return;
        }
        else {
            commit('data/setData', data, { root: true })
        }


        commit('clearQueue')
        commit('setLastSync')
    },
    checkConnection: async ({state, commit, getters, dispatch}) => {
        try {
            const isOnline = await ping()

            if ('status_code' in isOnline) {
                commit('setIsOnline', false)
            }
            else {
                // if we just came online and have no syncqueue, sync now
                if (!state.isOnline && isOnline.online && getters['queueLength'] === 0) {
                    dispatch('sync')
                }
                commit('setIsOnline', isOnline.online)
            }
        }
        catch (e) {
            commit('setIsOnline', false)
        }
    }
}

const mutations = {
    setIsOnline: (state: SyncState, status: boolean) => {
        state.isOnline = status
    },
    setLastSync: (state: SyncState) => {
        state.lastSync = new Date(Date.now() + (1000 * 60 * 60 * 2))
    },
    addToQueue: (state: SyncState, element: dbObject) => {
        if ('kanton' in element) {
            state.syncQueue.digsites.push(element)
        }
    
        if ('coordinates' in element) {
            state.syncQueue.sectors.push(element)
        }
    
        if ('position_number' in element) {
            state.syncQueue.positions.push(element)
        }
    
        if ('association' in element) {
            state.syncQueue.buildfindings.push(element)
        }
    
        if ('found_object_number' in element) {
            state.syncQueue.foundobjects.push(element)
        }
    
        if ('sample_number' in element) {
            state.syncQueue.samples.push(element)
        }
    
        if ('probenumber' in element) {
            state.syncQueue.probes.push(element)
        }
    
        if ('core_number' in element) {
            state.syncQueue.cores.push(element)
        }
    
        if ('point_name' in element) {
            state.syncQueue.measurementdatas.push(element)
        }


        if ('zone' in element) {
            state.syncQueue.foundsites.push(element)
        }
    },
    clearQueue: (state: SyncState) => {
        state.syncQueue = {
            digsites: [],
            sectors: [],
            positions: [],
            buildfindings: [],
            foundobjects: [],
            samples: [],
            probes: [],
            cores: [],
            measurementdatas: [],
            foundsites: []
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}