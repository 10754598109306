import {dateToString, generateUUID} from "@/utils"
import { Action, Commit } from "vuex"
import { State } from "."
import { Digsite, Sector, Position, BuildFinding, FoundObject, Sample, Probe, Core, MeasurementData, Foundsite, Data } from "@/types.gen"
import { dbModel } from "@/types"
import { saveDynamic } from "@/api/requests"

const digsite_key = 'APP_CURRENT_DIGSITE'
const data_key = 'APP_DATA'

export interface DataState {
    currentDigsite?: string,
    digsites: Digsite[],
    sectors: Sector[],
    positions: Position[],
    buildfindings: BuildFinding[],
    foundobjects: FoundObject[],
    samples: Sample[],
    probes: Probe[],
    cores: Core[],
    measurementdatas: MeasurementData[],
    Files: File[],
    foundsites: Foundsite[],
    data: Data[]
}

const savedDigsite = window.localStorage.getItem(digsite_key)
const savedDataString = window.localStorage.getItem(data_key);

let state: DataState = {
    currentDigsite: savedDigsite ? savedDigsite : undefined,
    digsites: [],
    sectors: [],
    positions: [],
    buildfindings: [],
    foundobjects: [],
    samples: [],
    probes: [],
    cores: [],
    measurementdatas: [],
    Files: [],
    foundsites: [],
    data: []
}


if (savedDataString) {
    state = JSON.parse(savedDataString) as DataState   
}

const getters = {
    getDigsites: (state: DataState) => state.digsites,
    getDigsiteById: (state: DataState) => (id: string) => {
        return state.digsites.find(digsite => digsite.id === id)
    },
    getCurrentDigsite: (state: DataState) => state.digsites.find(d => d.id === state.currentDigsite),

    /** Fundstellen */
    getFoundsites: (state: DataState) => {
        return state.foundsites
    },
    getFoundsiteById: (state: DataState) => (id: string) => {
        return state.foundsites.find(sector => sector.id === id)
    },
    getFoundsitesById: (state: DataState) => (ids: string[]) => {
        return state.foundsites.filter(sector => ids.includes(sector.id))
    },

    /** Sektoren */
    getSectors: (state: DataState) => {
        return state.sectors
    },
    getSectorById: (state: DataState) => (id: string) => {
        return state.sectors.find(sector => sector.id === id)
    },
    getSectorsById: (state: DataState) => (ids: string[]) => {
        return state.sectors.filter(sector => ids.includes(sector.id))
    },

    /** Positionen */
    getPositions: (state: DataState) => {
        return state.positions
    },
    getPositionById: (state: DataState) => (id: string) => {
        return state.positions.find(el => el.id === id)
    },
    getPositionsById: (state: DataState) => (ids: string[]) => {
        return state.positions.filter(el => ids.includes(el.id))
    },


    /** BuildFindings */
    getBuildFindings: (state: DataState) => {
        return state.buildfindings
    },
    getBuildFindingById: (state: DataState) => (id: string) => {
        return state.buildfindings.find(el => el.id === id)
    },
    getBuildFindingsById: (state: DataState) => (ids: string[]) => {
        return state.buildfindings.filter(el => ids.includes(el.id))
    },

    /** FoundObject */
    getFoundObjects: (state: DataState) => {
        return state.foundobjects
    },
    getFoundObjectById: (state: DataState) => (id: string) => {
        return state.foundobjects.find(el => el.id === id)
    },
    getFoundObjectsById: (state: DataState) => (ids: string[]) => {
        return state.foundobjects.filter(el => ids.includes(el.id))
    },

    /** Sample */
    getSamples: (state: DataState) => {
        return state.samples
    },
    getSampleById: (state: DataState) => (id: string) => {
        return state.samples.find(el => el.id === id)
    },
    getSamplesById: (state: DataState) => (ids: string[]) => {
        return state.samples.filter(el => ids.includes(el.id))
    },

    /** Probe */
    getProbes: (state: DataState) => {
        return state.probes
    },
    getProbeById: (state: DataState) => (id: string) => {
        return state.probes.find(el => el.id === id)
    },
    getProbesById: (state: DataState) => (ids: string[]) => {
        return state.probes.filter(el => ids.includes(el.id))
    },

    /** Core */
    getCores: (state: DataState) => {
        return state.cores
    },
    getCoreById: (state: DataState) => (id: string) => {
        return state.cores.find(el => el.id === id)
    },
    getCoresById: (state: DataState) => (ids: string[]) => {
        return state.cores.filter(el => ids.includes(el.id))
    },

    /** MeasurementData */
    getMeasurementDatas: (state: DataState) => {
        return state.measurementdatas
    },
    getMeasurementDataById: (state: DataState) => (id: string) => {
        return state.measurementdatas.find(el => el.id === id)
    },
    getMeasurementDatasById: (state: DataState) => (ids: string[]) => {
        return state.measurementdatas.filter(el => ids.includes(el.id))
    },

    /** Data */
    getDataByOtherId: (state: DataState) => (id: string) => {
        return state.data.filter(el => el.other_id === id)
    },

    search: (state: DataState) => (text: string) => {
        const results = []

        text = text.toLowerCase()   

        for (const [typeKey, models] of Object.entries(state)) {
            if (typeKey !== 'currentDigsite') {
                for (const [modelKey, model] of Object.entries(models)) {
                    // @ts-ignore
                    for (const [propKey, value] of Object.entries(model)) {
                        if (String(value).toLowerCase().indexOf(text) !== -1) {
                            results.push({
                                type: typeKey,
                                model: model
                            })

                            break
                        }
                    }
                }
            }
        }

        return results;
    }
}

function createOrUpdateDynamic<T extends dbModel>(element: T, name: string, state: DataState, commit: Commit, rootGetters: any) {
    element.altered_at = dateToString(new Date())
    element.altered_from = rootGetters['user/getName']

    if (element.id) {
        // Update
        commit('update' + name, element)
    }
    else {
        // Create
        element.id = generateUUID()

        commit('create' + name, element)
    }

    if (rootGetters['sync/isOnline']) {
        let pathName = name.toLowerCase()

        if (pathName == 'buildfinding') {
            pathName = 'buildfindings'
        }

        saveDynamic<T>(pathName, element).catch((error) => {
            alert("Fehler beim Speichern. Bitte überprüfen Sie die eingegebenen Daten.");
        })
    }
    else {
        commit('sync/addToQueue', element, { root: true })
    }
}

const actions: Record<string, Action<DataState, State>> = {
    /** Digsites */
    createOrUpdateDigsite: async ({ state, commit, rootGetters }, digsite: Digsite) => {
        createOrUpdateDynamic(digsite, "Digsite", state, commit, rootGetters)
    },
    createOrUpdateSector: ({ state, commit, rootGetters }, sector: Sector) => {
        createOrUpdateDynamic(sector, "Sector", state, commit, rootGetters)
    },
    createOrUpdatePosition: ({ state, commit, rootGetters }, position: Position) => {
        createOrUpdateDynamic(position, "Position", state, commit, rootGetters)
    },
    createOrUpdateBuildFinding: ({ state, commit, rootGetters }, position: BuildFinding) => {
        createOrUpdateDynamic(position, "BuildFinding", state, commit, rootGetters)
    },
    createOrUpdateFoundObject: ({ state, commit, rootGetters }, position: FoundObject) => {
        createOrUpdateDynamic(position, "FoundObject", state, commit, rootGetters)
    },
    createOrUpdateSample: ({ state, commit, rootGetters }, position: Sample) => {
        createOrUpdateDynamic(position, "Sample", state, commit, rootGetters)
    },
    createOrUpdateProbe: ({ state, commit, rootGetters }, position: Probe) => {
        createOrUpdateDynamic(position, "Probe", state, commit, rootGetters)
    },
    createOrUpdateCore: ({ state, commit, rootGetters }, position: Core) => {
        createOrUpdateDynamic(position, "Core", state, commit, rootGetters)
    },
    createOrUpdateMeasurementData: ({ state, commit, rootGetters }, position: MeasurementData) => {
        createOrUpdateDynamic(position, "MeasurementData", state, commit, rootGetters)
    },
    createOrUpdateFoundsite: ({ state, commit, rootGetters }, position: Foundsite) => {
        createOrUpdateDynamic(position, "Foundsite", state, commit, rootGetters)
    }
}

const mutations = {
    setCurrentDigsite: (state: DataState, id: string) => {
        window.localStorage.setItem(digsite_key, id)
        state.currentDigsite = id
    },

    /** Digsites */
    updateFoundsite: (state: DataState, element: Foundsite) => {
        const newElements = state.foundsites.map(s => s.id === element.id ? element : s)

        state.foundsites = newElements
    },
    createFoundsite: (state: DataState, element: Foundsite) => {
        state.foundsites.push(element)
    },
    setFoundsites: (state: DataState, digsites: Foundsite[]) => {
        state.foundsites = digsites
    },
    
    /** Digsites */
    updateDigsite: (state: DataState, element: Digsite) => {
        const newElements = state.digsites.map(s => s.id === element.id ? element : s)

        state.digsites = newElements
    },
    createDigsite: (state: DataState, element: Digsite) => {
        state.digsites.push(element)
    },
    setDigsites: (state: DataState, digsites: Digsite[]) => {
        state.digsites = digsites
    },

    /** Sectors */
    updateSector: (state: DataState, element: Sector) => {
        const newElements = state.sectors.map(s => s.id === element.id ? element : s)

        state.sectors = newElements
    },
    createSector: (state: DataState, element: Sector) => {
        state.sectors.push(element)
    },
    setSectors: (state:DataState, sectors: Sector[]) => {
        state.sectors = sectors
    },

    
    /** Positions */
    updatePosition: (state: DataState, element: Position) => {
        const newElements = state.positions.map(s => s.id === element.id ? element : s)

        state.positions = newElements
    },
    createPosition: (state: DataState, element: Position) => {
        state.positions.push(element)
    },
    setPositions: (state:DataState, elements: Position[]) => {
        state.positions = elements
    },

    
    /** BuildFinding */
    updateBuildFinding: (state: DataState, element: BuildFinding) => {
        const newElements = state.buildfindings.map(s => s.id === element.id ? element : s)

        state.buildfindings = newElements
    },
    createBuildFinding: (state: DataState, element: BuildFinding) => {
        state.buildfindings.push(element)
    },
    setBuildFinding: (state:DataState, elements: BuildFinding[]) => {
        state.buildfindings = elements
    },


    /** FoundObject */
    updateFoundObject: (state: DataState, element: FoundObject) => {
        const newElements = state.foundobjects.map(s => s.id === element.id ? element : s)

        state.foundobjects = newElements
    },
    createFoundObject: (state: DataState, element: FoundObject) => {
        state.foundobjects.push(element)
    },
    setFoundObject: (state:DataState, elements: FoundObject[]) => {
        state.foundobjects = elements
    },

    
    /** Sample */
    updateSample: (state: DataState, element: Sample) => {
        const newElements = state.samples.map(s => s.id === element.id ? element : s)

        state.samples = newElements
    },
    createSample: (state: DataState, element: Sample) => {
        state.samples.push(element)
    },
    setSample: (state:DataState, elements: Sample[]) => {
        state.samples = elements
    },
    
    /** Probe */
    updateProbe: (state: DataState, element: Probe) => {
        const newElements = state.probes.map(s => s.id === element.id ? element : s)

        state.probes = newElements
    },
    createProbe: (state: DataState, element: Probe) => {
        state.probes.push(element)
    },
    setProbe: (state:DataState, elements: Probe[]) => {
        state.probes = elements
    },
    
    /** Core */
    updateCore: (state: DataState, element: Core) => {
        const newElements = state.cores.map(s => s.id === element.id ? element : s)

        state.cores = newElements
    },
    createCore: (state: DataState, element: Core) => {
        state.cores.push(element)
    },
    setCore: (state:DataState, elements: Core[]) => {
        state.cores = elements
    },
    
    /** MeasurementData */
    updateMeasurementData: (state: DataState, element: MeasurementData) => {
        const newElements = state.measurementdatas.map(s => s.id === element.id ? element : s)

        state.measurementdatas = newElements
    },
    createMeasurementData: (state: DataState, element: MeasurementData) => {
        state.measurementdatas.push(element)
    },
    setMeasurementData: (state:DataState, elements: MeasurementData[]) => {
        state.measurementdatas = elements
    },

    setData: (state: DataState, data: Data[]) => {
        state.data = data
    },

    addData: (state: DataState, data: Data) => {
        state.data.push(data)
    }
    
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}