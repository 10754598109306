import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["disabled", "id", "value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.name,
      class: "col-form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("select", {
      disabled: _ctx.setViaRoute,
      id: _ctx.name,
      class: "form-select",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (el) => {
        return (_openBlock(), _createElementBlock("option", {
          value: el.id,
          key: el.id
        }, _toDisplayString(_ctx.getDescription(el)), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_3)
  ]))
}