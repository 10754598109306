
import {computed, defineComponent} from 'vue'
import {dbObject} from "@/types";
import { useStore } from 'vuex';
import { getElementDescription, getElementLink } from '@/utils/models'

export default defineComponent({
  components: {},
  emits: ['update:modelValue'],
  props: {
    id: {
        required: true,
        type: String
    },
    typeName: {
      required: true,
      type: String
    },
  },
  setup(props) {
    const store = useStore()

    const element = computed(() => store.getters['data/get' + props.typeName + 'ById'](props.id))

    function getDescription(element: dbObject) {
      return getElementDescription(element, store)
    }

    function getLink(element: dbObject) {
      return getElementLink(element)
    }

    return {
      element,
      getDescription,
      getLink
    }
  }
})
