
import {defineComponent, PropType} from 'vue'
import {dbObject} from "@/types";
import {data} from "@/data";
import DynamicRelationUp from './DynamicRelationUp.vue';
import DynamicRelationDown from './DynamicRelationDown.vue';

export default defineComponent({
  components: { DynamicRelationUp, DynamicRelationDown },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
        required: true,
        type: Object as PropType<dbObject>
    },
    typeName: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    const fields = data[props.typeName]

    return {
      fields,
    }
  }
})
