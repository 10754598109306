import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { InjectionKey } from 'vue'
import user, { UserState } from './user.state'
import data, { DataState } from './data.state'
import sync, { SyncState } from './sync.state'
import VuexPersistence from 'vuex-persist'

export interface State {
  user: UserState,
  data: DataState,
  sync: SyncState
}

const vuexLocal = new VuexPersistence<State>({
  storage: window.localStorage
})

const store = createStore<State>({
  strict: true,
  modules: {
    user,
    data,
    sync
  },
  plugins: [vuexLocal.plugin]
})

export const key: InjectionKey<Store<State>> = Symbol()

export default store

export function useStore(): Store<State> {
  return baseUseStore<State>(key)
}