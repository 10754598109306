
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { userRequest } from "./api/requests";
import { removeToken, useToken } from "./bootstrap/state";

export default defineComponent({
  setup() {
    const token = useToken();
    const store = useStore();
    const router = useRouter();


    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })


    async function checkUser() {

          const response = await userRequest();

          if ("status_code" in response) {
            // intentionally left blank
            //removeToken();
          } else {
            store.dispatch('sync/sync')
            store.commit("user/setEmail", response.email);
            store.commit("user/setFirstname", response.firstname);
            store.commit("user/setLastname", response.lastname);
          }
    }

    onMounted(async () => {
      if (token.value) {
        try {
          checkUser()
        }
        catch {
          // do nothing, maybe we still are validly logged in
        }

      } else {
          router.push("/account/login");
      }

      store.dispatch('sync/checkConnection')
      window.setInterval(async () => {
        store.dispatch('sync/checkConnection')

        if (isOnline.value) {
          checkUser()
        }
      }, 1000 * 60 * 5)
    });
  },
});
