
import { BuildFinding } from "@/types.gen";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const sectors = computed<BuildFinding[]>(() => store.getters["data/getBuildFindings"]);

    const {
      setSort,
      sortedData
    } = useSort<BuildFinding>(sectors, 'description')

    function onClick(sector: BuildFinding) {
      router.push("/buildfinding/" + sector.id);
    }

    return {
      sectors,
      onClick,
      formatDate,
      formatDateTime,
      sortedData
    };
  },
});
