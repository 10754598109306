
import { Sample } from "@/types.gen";
import { getElementDescriptionById } from "@/utils/models";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const positions = computed<Sample[]>(() => store.getters["data/getSamples"]);

    const {
      setSort,
      sortedData
    } = useSort<Sample>(positions, 'sample_number')

    function onClick(position: Sample) {
      router.push("/sample/" + position.id);
    }

    
    function getPositionName(id: string) {
      const value = store.getters['data/getPositionById'](id)

      if (value) {
        return value.postion_number
      }

      return ""
    }



    function getDescription(id: string) {
        return getElementDescriptionById(id, store)
      }

    return {
      positions,
      onClick,
      formatDate,
      formatDateTime,
      sortedData,
      getPositionName,
      getDescription
    };
  },
});
