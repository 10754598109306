
import { defineComponent } from 'vue'

export default defineComponent({
    emits: ['submit'],
    props: {
        title: {
            required: true,
            type: String
        },
        buttonLabel: {
            type: String,
            default: () => 'Absenden'
        }
    }
})
