
export interface UserState {
    email: string;
    firstname: string;
    lastname: string;
}

const state: UserState = {
    email: '',
    firstname: '',
    lastname: ''
}

const getters = {
    getName: (state: UserState) => {
        return state.firstname + ' ' + state.lastname
    }
}

const actions = {}

const mutations = {
    setEmail(state: UserState, email: string) {
        state.email = email
    },
    setFirstname(state: UserState, firstname: string) {
        state.firstname = firstname
    },
    setLastname(state: UserState, lastname: string) {
        state.lastname = lastname
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}