
import FormContainer from "@/components/forms/FormContainer.vue";
import Modal from "@/components/Modal.vue";
import {useForm} from "@/utils/forms";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import DynamicForm from "@/components/forms/DynamicForm.vue";
import DynamicRelationList from "@/components/relations/DynamicRelationList.vue";
import { BuildFinding } from "@/types.gen";
import ModalAccordion from "@/components/ModalAccordion.vue";
import Gallery from "@/components/images/Gallery.vue";

export default defineComponent({
  components: { DynamicForm, FormContainer, Modal, DynamicRelationList, ModalAccordion, Gallery },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const { data, save } = useForm<BuildFinding>('BuildFinding', store, router, route, {
      doc_complete: false
    })

    return {
      data,
      save
    };
  },
});
