
import {defineComponent, onMounted, ref, watch} from "vue";
import {dateToString} from "@/utils";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    layout: {
      type: String,
      default: () => "vertical",
    },
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: () => "text",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const value = ref<string>()

    onMounted(() => {
      if (props.modelValue) {
        value.value = props.modelValue
      }
      else {
        value.value = dateToString(new Date())
      }
    })

    watch(value, () => {
      if (value.value) {
        const dateValue = dateToString(new Date(value.value))

        emit('update:modelValue', dateValue)
      }
    })

    return {
      value
    }
  }
});
