
import { Digsite } from "@/types.gen";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDateTime } from '../../utils'

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const projects = computed<Digsite[]>(
      () => store.getters["data/getDigsites"]
    );

    function onSelectSite(digsite: Digsite) {
      store.commit("data/setCurrentDigsite", digsite.id);
      router.push("/sectors/");
    }

    function onClick(digsite: Digsite, event: Event) {
      if ((event.target as HTMLElement).tagName !== 'BUTTON') {
        router.push("/digsite/" + digsite.id)
      }
    }

    return {
      projects,
      onSelectSite,
      formatDateTime,
      onClick
    };
  },
});
