import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "gallery" }
const _hoisted_2 = { class: "images" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "newFile" }
const _hoisted_5 = {
  type: "file",
  ref: "fileInput"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (file) => {
              return (_openBlock(), _createElementBlock("li", {
                key: file.id
              }, [
                _createElementVNode("a", {
                  href: _ctx.getLink(file),
                  target: "_blank"
                }, _toDisplayString(file.name), 9, _hoisted_3)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
            placeholder: "Datei hochladen"
          }, null, 512), [
            [_vModelText, _ctx.newName]
          ]),
          _createElementVNode("input", _hoisted_5, null, 512),
          _createElementVNode("input", {
            type: "submit",
            value: "Absenden",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
          })
        ])
      ])
    ], 512), [
      [_vShow, _ctx.isOnline]
    ]),
    _withDirectives(_createElementVNode("div", null, " Dateien sind im Offline Modus nicht verfügbar. ", 512), [
      [_vShow, !_ctx.isOnline]
    ])
  ], 64))
}