import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = { class: "accordion-item" }
const _hoisted_3 = {
  class: "accordion-header",
  id: "relations"
}
const _hoisted_4 = { class: "accordion-body" }
const _hoisted_5 = { class: "accordion-item" }
const _hoisted_6 = {
  class: "accordion-header",
  id: "relations"
}
const _hoisted_7 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("button", {
          class: "accordion-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openRelations = !_ctx.openRelations)),
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#relationsContent",
          "aria-expanded": "true",
          "aria-controls": "collapseOne"
        }, " Beziehungen ")
      ]),
      _createElementVNode("div", {
        id: "relationsContent",
        class: _normalizeClass(["accordion-collapse collapse", _ctx.openRelations ? 'show' : '']),
        "aria-labelledby": "relations",
        "data-bs-parent": "#accordionExample"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "relations")
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", _hoisted_6, [
        _createElementVNode("button", {
          class: "accordion-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openImages = !_ctx.openImages)),
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#relationsContent",
          "aria-expanded": "true",
          "aria-controls": "collapseOne"
        }, " Dateien ")
      ]),
      _createElementVNode("div", {
        id: "relationsContent",
        class: _normalizeClass(["accordion-collapse collapse", _ctx.openImages ? 'show' : '']),
        "aria-labelledby": "relations",
        "data-bs-parent": "#accordionExample"
      }, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "images")
        ])
      ], 2)
    ])
  ]))
}