
import { FoundObject } from "@/types.gen";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import {data} from "@/data";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";
import { getTranslationValue } from "@/utils/locales";
import { getElementDescriptionById } from "@/utils/models";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const foundobjects = computed<FoundObject[]>(() => store.getters["data/getFoundObjects"]);

    const {
      setSort,
      sortedData
    } = useSort<FoundObject>(foundobjects, 'found_object_number')

    function onClick(position: FoundObject) {
      router.push("/foundobject/" + position.id);
    }

    function exportData() {
      console.log(foundobjects.value)
      let exportString = "data:text/csv;charset=utf-8,";
      const fields = data["FoundObject"]

      for (var fieldName in fields) {
        exportString += getTranslationValue("FoundObject.fields." + fieldName) + ";"
      }
      exportString += "\n"

      sortedData.value.forEach(object => {
        for (var fieldName in fields) {
          const field = fields[fieldName]
          // @ts-ignore
          const value = object[fieldName] as any

          if (field === 'boolean') {
            exportString += (value ? "Ja" : "Nein") + ";";
          }
          else if (field === 'date') {
            exportString += formatDateTime(value) + ";"
          }
          else if (field === "number") {
            exportString += value + ";"
          }
          else if (field === "string") {
            exportString += "\"" + value.replace("\\", "\\\\") + "\";"
          }
          else if (field === "relation_down") {
            exportString += "relation_down;"
          }
          else {
            if (value) {
              exportString += getElementDescriptionById(value, store) + ";"
            }
            else {
              exportString += value + ";"
            }
          }
        }

        exportString += "\n";
        return false;
      })


      console.log(exportString)
      
      var encodedUri = encodeURI(exportString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", getTranslationValue("FoundObject.nameplural") + ".csv");
      //document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".

    }


    function getDescription(id: string) {
        return getElementDescriptionById(id, store)
      }

    return {
      exportData,
      foundobjects,
      onClick,
      formatDate,
      formatDateTime,
      sortedData,
      getDescription
    };
  },
});
