
import { Position } from "@/types.gen";
import { getElementDescriptionById } from "@/utils/models";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const positions = computed<Position[]>(() => store.getters["data/getPositions"]);

    const {
      setSort,
      sortedData
    } = useSort<Position>(positions, 'postion_number')

    function onClick(position: Position) {
      router.push("/position/" + position.id);
    }

    function getDescription(id: string) {
      return getElementDescriptionById(id, store)
    }

    return {
      positions,
      onClick,
      formatDate,
      formatDateTime,
      sortedData,
      getDescription
    };
  },
});
