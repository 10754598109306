import { dbObject } from "@/types";
import { Position } from "@/types.gen";
import { Store, useStore } from "vuex";

export function getElementById<dbObject>(id: string, store: any) : dbObject | null {
    if (!store) {
        const store = useStore()
    }

    let el = store.getters['data/getDigsiteById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getFoundsiteById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getSectorById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getPositionById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getBuildFindingById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getFoundObjectById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getSampleById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getProbeById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getMeasurementDataById'](id)
    if (el) {
        return el
    }

    el = store.getters['data/getMeasurementDatasById'](id)
    if (el) {
        return el
    }

    return null
}

export function getElementDescriptionById(id: string, store: any) {
    console.log("Getting ", id)
    const el = getElementById(id, store)
    if (el) {
        // @ts-ignore
        return getElementDescription(el, store)
    }
    return "Element nicht gefunden";
}

export function getElementDescription(element: dbObject, store: any): string {
    if (!store) {
        const store = useStore()
    }
    
    if ('kanton' in element) {
        return element.name
    }

    if ('coordinates' in element) {
        return store.getters['data/getFoundsiteById'](element.foundsite_id).name + " -> Sektor " + element.sector_number
    }

    if ('postion_number' in element) {
        const position = getElementById<Position>(element.sector_id, store)
        return (position ? (getElementDescription(position, store) + ' -> ') : '') + 'Position ' + element.postion_number
    }

    if ('plan_drawing_number' in element) {
        return element.plan_drawing_number
    }

    if ('found_object_number' in element) {
        const position = getElementById<Position>(element.position_id, store)
        return (position ? (getElementDescription(position, store) + ' -> ') : '') + element.found_object_number
    }

    if ('sample_number' in element) {
        const position = getElementById<Position>(element.position_id, store)
        return (position ? (getElementDescription(position, store) + ' -> ') : '') + element.sample_number
    }

    if ('probenumber' in element) {
        return String(element.probenumber)
    }

    if ('core_number' in element) {
        return String(element.core_number)
    }

    if ('point_name' in element) {
        return element.point_name
    }

    if ('zone' in element) {
        return element.name
    }

    return 'Unbekanntes Element'
}

export function getElementLink(element: dbObject) {
    if ('zone' in element) {
        return '/foundsite/' + (element.id ? element.id : '')
    }
    if ('kanton' in element) {
        return '/digsite/' + (element.id ? element.id : '')
    }

    if ('coordinates' in element) {
        return '/sector/' + (element.id ? element.id : '')
    }

    if ('postion_number' in element) {
        return '/position/' + (element.id ? element.id : '')
    }

    if ('plan_drawing_number' in element) {
        return '/buildfinding/' + (element.id ? element.id : '')
    }

    if ('found_object_number' in element) {
        return '/foundobject/' + (element.id ? element.id : '')
    }

    if ('sample_number' in element) {
        return '/sample/' + (element.id ? element.id : '')
    }

    if ('probenumber' in element) {
        return '/probe/' + (element.id ? element.id : '')
    }

    if ('core_number' in element) {
        return '/core/' + (element.id ? element.id : '')
    }

    if ('point_name' in element) {
        return '/measurementdata/' + (element.id ? element.id : '')
    }
}