import { http } from "@/bootstrap/axios";
import { Digsite, Sector, Position, Data } from "@/types.gen";
import axios from "axios";

export interface httpException {
    description: string;
    error: string;
    status_code: number;
}

export interface registerLoginResponse {
    access_token: string;
    email: string;
    firstname: string;
    lastname: string;
}

export interface userResponse {
    access_token: string;
    email: string;
    firstname: string;
    lastname: string;
}

export interface pingResponse {
    online: boolean
}

export async function ping() {
    return get<pingResponse>('/ping')
}

export async function userRequest() {
    return get<userResponse>('/auth/user')
}

export async function loginRequest(email: string, password: string) {
    return post<registerLoginResponse>('/auth/login', {
        email: email,
        password: password
    })
}

export async function registerRequest(email: string, password: string, firstname: string, lastname: string) {
    return post<registerLoginResponse>('/auth/register', {
        email: email,
        password: password,
        firstname: firstname,
        lastname: lastname
    })
}

export async function saveData(data: Data) {
    return post<any>('/data/data', data)
}

export async function getDynamic<T>(name: string) {
    return get<T[]>('/data/' + name)
}

export async function saveDynamic<T>(name: string, element: T) {
    return post<any>('/data/' + name, element)
}

export async function getDigsites() {
    return get<Digsite[]>('/data/digsite')
}

export async function saveDigsite(digsite: Digsite) {
    return post<any>('/data/digsite', digsite)
}

export async function getSectors() {
    return get<Sector[]>('/data/sector')
}

export async function saveSector(sector: Sector) {
    return post<any>('/data/sector', sector)
}

export async function getPositions() {
    return get<Position[]>('/data/position')
}

export async function savePosition(el: Position) {
    return post<any>('/data/position', el)
}

async function get<T>(path: string): Promise<httpException | T> {
    try {
        const response = await http.get(path)

        return response.data as T
    }
    catch(e) {
        if (axios.isAxiosError(e) && e.response && e.response.data.status_code) {
            return e.response?.data as httpException
        }
        else if ((e.message as string).indexOf("401")) {
            return {
                description: "Unauthorized",
                error: "401",
                status_code: 401
            }
        }
        else {
            return {
                description: "Unknown",
                error: "Unknown",
                status_code: 400
            }
        }
    }
}

async function post<T>(path: string, data: any): Promise<httpException | T> {
    try {
        const response = await http.post(path, data)

        return response.data as T
    }
    catch(e) {
        if (axios.isAxiosError(e) && e.response && e.response.data.status_code) {
            throw e
        }
        else if ((e.message as string).indexOf("401")) {
            return {
                description: "Unauthorized",
                error: "401",
                status_code: 401
            }
        }
        else {
            throw e
        }
    }
}