
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import FormContainer from "../../components/forms/FormContainer.vue";
import FormInput from "../../components/forms/FormInput.vue";
import { loginRequest, httpException } from "@/api/requests";
import { setToken } from "./../../bootstrap/state";

export default defineComponent({
  components: { FormContainer, FormInput },
  setup() {
    const store = useStore()
    const router = useRouter()
    const email = ref("");
    const password = ref("");
    const error = ref<httpException>();

    async function login() {
      const response = await loginRequest(email.value, password.value);

      console.log(response);

      if ("status_code" in response) {
        error.value = response;
      } else {
        setToken(response.access_token);
        store.commit("user/setEmail", response.email);
        store.commit("user/setFirstname", response.firstname);
        store.commit("user/setLastname", response.lastname);

        router.push("/app/home");
      }
    }

    return {
      email,
      password,
      login,
      error,
    };
  },
});
