import {computed, ref } from "vue";
import {RouteLocation, Router} from "vue-router";
import {Store} from "vuex";
import {State} from "@/store";
import { data as baseData } from '@/data'

export const useForm = function<T> (typeName: string, store: Store<State>, router: Router, route: RouteLocation, defaultValues: Partial<T> = {}) {
    const element = computed<T>(() => store.getters['data/get' + typeName + 'ById'](route.params.id) as T)

    const allFields = baseData[typeName]

    const relationValues = {}

    for (const key in allFields) {
        const type = allFields[key]

        if (typeof type == 'object') {
            if (type.way == 'up') {
                // @ts-ignore
                defaultValues[key] = ''
            }
        }
    }
    const data = ref<T>({ ... relationValues, ... defaultValues, ... element.value })

    function save() {
        store.dispatch('data/createOrUpdate' + typeName, data.value)

        router.back()
    }

    return {
        data,
        save
    }
}