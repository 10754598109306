
import {defineComponent, PropType, ref, watch} from 'vue'
import {dbObject} from "@/types";
import {data} from "@/data";
import FormInput from "@/components/forms/FormInput.vue";
import FormDate from "@/components/forms/FormDate.vue";
import FormNumber from "@/components/forms/FormNumber.vue";
import FormRelationUp from "@/components/forms/FormRelationUp.vue";
import FormCheckbox from './FormCheckbox.vue';

export default defineComponent({
  components: { FormInput, FormDate, FormNumber, FormRelationUp, FormCheckbox },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
        required: true,
        type: Object as PropType<dbObject>
    },
    typeName: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    const fields = data[props.typeName]

    const copyValues = ref<dbObject>(props.modelValue)

    watch(copyValues, () => {
      emit('update:modelValue', copyValues.value)
    })

    return {
      fields,
      copyValues
    }
  }
})
