import { dbObject } from "@/types";
import { computed, ComputedRef, ref } from "vue";

function useSort<dbObject>(data: ComputedRef<dbObject[]>, defaultSort: string) {
    const sort = ref(defaultSort)
    function setSort(newSort: string) {
        sort.value = newSort
    }

    const sortedData = computed<dbObject[]>(() => {
        return [...data.value].sort((el1, el2) => {
            // @ts-ignore
            return String(el1[sort.value]).localeCompare(el2[sort.value], undefined, {numeric: true, sensitivity: 'base'})
        })
    })

    return {
        setSort,
        sortedData
    }
}

export {
    useSort
}