
import {defineComponent, PropType, ref} from "vue";
import {Difference as differenceType} from "@/types";

export default defineComponent({
  emits: ['setLocal', 'setLive'],
  props: {
    difference: {
      required: true,
      type: Object as PropType<differenceType>
    },
    labelProp: {
      required: true,
      type: String
    }
  },
  setup () {
    const activeId = ref('')

    return {
      activeId
    }
  }
});
