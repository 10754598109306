import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "mb-3 buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", null, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default"),
    _renderSlot(_ctx.$slots, "error"),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "buttons", {}, () => [
        _createElementVNode("button", {
          type: "submit",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"])),
          class: "btn btn-primary"
        }, _toDisplayString(_ctx.buttonLabel), 1)
      ])
    ]),
    _renderSlot(_ctx.$slots, "footer")
  ]))
}