
import { defineComponent, ref } from "vue";

export default defineComponent({
    setup() {
        const openRelations = ref(false)
        const openImages = ref(false)

        return {
            openRelations,
            openImages
        }
    }
});
