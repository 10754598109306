
import { Sector } from "@/types.gen";
import { useSort } from "@/utils/sort";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import TableView from "../../components/table/TableView.vue";
import { formatDate, formatDateTime } from "../../utils";

export default defineComponent({
  components: { TableView },
  setup() {
    const store = useStore();
    const router = useRouter();

    const sectors = computed<Sector[]>(() => store.getters["data/getSectors"]);

    const {
      setSort,
      sortedData
    } = useSort<Sector>(sectors, 'sector_number')

    function onClick(sector: Sector) {
      router.push("/sector/" + sector.id);
    }

    function getFoundsiteName(id: string) {
      const el = store.getters['data/getFoundsiteById'](id)

      if (el) {
        return el.name
      }

      return ""
    }

    return {
      sectors,
      onClick,
      formatDate,
      formatDateTime,
      sortedData,
      getFoundsiteName
    };
  },
});
