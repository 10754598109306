
import store from "@/store";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit'],
  setup () {
    const router = useRouter()

    function back() {
      router.back()
    }

    function clickOutside(path: HTMLElement[]) {
      if (!path.find(el => el.classList && el.classList.contains('modal-content'))) {
        back()
      }
    }


    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })


    return {
      back,
      clickOutside,
      isOnline
    }
  }
});
