import { ref } from "vue";

const token_key = 'APP_LOGIN_TOKEN'

const token = ref('')

export function loadState() {
    const savedToken = window.localStorage.getItem(token_key)
    if (savedToken) {
        token.value = savedToken
    }
}

export function useToken() {
    return token
}

export function setToken(newToken: string) {
    token.value = newToken
    window.localStorage.setItem(token_key, newToken)
}

export function removeToken() {
    token.value = ''
    window.localStorage.removeItem(token_key)
}