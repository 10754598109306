
import { saveData } from "@/api/requests";
import { Data } from "@/types.gen";
import { dateToString, generateUUID } from "@/utils";
import { getElementLink } from "@/utils/models";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const dropArea = ref<HTMLDivElement>()
        const dropReady = ref(false)
        const store = useStore()

        const newName = ref('')
        const fileInput = ref<HTMLInputElement>()

        const isOnline = computed(() => {
            return store.getters['sync/isOnline'] == true
        })

        const data = computed<Data[]>(() => store.getters['data/getDataByOtherId'](props.id ))

        async function uploadFile() {
            if (fileInput.value && fileInput.value.files) {
                const file = fileInput.value.files[0]

                if (file && newName.value !== '') {
                    console.log(file.type)

                    if (file.type === 'text/plain') {
                        const text =  await file.text()

                        const newData = {
                            name: newName.value,
                            other_id: props.id,
                            data: text,
                            id: generateUUID(),
                            altered_at: dateToString(new Date()),
                            altered_from: 'unknown',
                            type: file.type
                        } as Data

                        const response = await saveData(newData)

                        newName.value = ''
                        fileInput.value.value = ''

                        store.commit('data/addData', newData)
                    }
                    else {
                        alert("Zurzeit werden nur Textdateien (.txt) unterstützt.")
                    }
                }
            }
        }

        onMounted(() => {
            if (dropArea.value) {
                const htmlElement = dropArea.value
                console.log('set events', htmlElement)

                ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                    htmlElement.addEventListener(eventName, (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }, false)
                })

                htmlElement.addEventListener('dragleave', () => {
                    dropReady.value = false
                })

                htmlElement.addEventListener('dragenter', () => {
                    dropReady.value = true
                })

                htmlElement.addEventListener('drop', (e) => {
                    dropReady.value = false;
                    if (e.dataTransfer) {
                        const files = e.dataTransfer.files;
                        ([...files]).forEach(file => {
                            console.log(file)
                        })
                    }

                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }, false)
            }
        })

        function getLink(data: Data) {
            return process.env.VUE_APP_SERVER + 'data/dataviewer?id=' + data.id
        }

        return {
            data,
            dropArea,
            dropReady,
            isOnline,
            newName,
            fileInput,
            uploadFile,
            getLink
        }
    }
});
