import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal-dialog",
  style: {"min-width":"80%"}
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createElementVNode("div", {
      class: "modal fade show",
      tabindex: "-1",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickOutside($event.path)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            }, " Schliessen "),
            (_ctx.showSaveButton)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  disabled: !_ctx.isOnline,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit'))),
                  type: "button",
                  class: "btn btn-primary"
                }, "Speichern", 8, _hoisted_7))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}