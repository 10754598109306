
import {computed, defineComponent} from 'vue'
import {dbObject} from "@/types";
import { useStore } from 'vuex';
import { getElementDescription, getElementLink } from '@/utils/models'
import { data } from '@/data';

export default defineComponent({
  components: {},
  emits: ['update:modelValue'],
  props: {
    thisId: {
        required: true,
        type: String
    },
    targetType: {
      required: true,
      type: String
    },
    selfType: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const store = useStore()

    const fieldNames = data[props.targetType]

    const elements = computed(() => {
      const targetFieldName = props.selfType.toLowerCase() + "_id" as keyof dbObject
      const allElements: dbObject[] = store.getters['data/get' + props.targetType + 's']
      
      return allElements.filter(el => {
        return (el[targetFieldName] as string) == props.thisId
      })
    })

    function getDescription(element: dbObject) {
      return getElementDescription(element, store)
    }

    function getLink(element: dbObject) {
      return getElementLink(element)
    }

    return {
      getDescription,
      elements,
      getLink,
      fieldNames
    }
  }
})
