import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_relation_up = _resolveComponent("dynamic-relation-up")!
  const _component_dynamic_relation_down = _resolveComponent("dynamic-relation-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (type, field) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: field }, [
        (type.way && type.way === 'up')
          ? (_openBlock(), _createBlock(_component_dynamic_relation_up, {
              key: 0,
              id: _ctx.modelValue[field],
              typeName: type.to,
              way: type.way
            }, null, 8, ["id", "typeName", "way"]))
          : _createCommentVNode("", true),
        (type.way && type.way === 'down')
          ? (_openBlock(), _createBlock(_component_dynamic_relation_down, {
              key: 1,
              thisId: _ctx.modelValue.id,
              targetType: type.to,
              selfType: _ctx.typeName
            }, null, 8, ["thisId", "targetType", "selfType"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}