import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "col-sm-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_ctx.layout === 'horizontal')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          for: _ctx.name,
          class: "col-form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2),
        _createVNode(_component_Datepicker, {
          format: "dd.MM.yyyy hh:mm",
          id: _ctx.name,
          name: _ctx.name,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          autoApply: "",
          clearable: false,
          locale: "de-CH"
        }, null, 8, ["id", "name", "modelValue"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("label", {
          for: _ctx.name,
          class: "col-sm-2 col-form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Datepicker, {
            format: "dd.MM.yyyy hh:mm",
            id: _ctx.name,
            name: _ctx.name,
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
            autoApply: "",
            clearable: false,
            locale: "de-CH"
          }, null, 8, ["id", "name", "modelValue"])
        ])
      ]))
}