import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "list-group-item list-group-item-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" Hat folgende " + _toDisplayString(_ctx.$t(`${_ctx.targetType}.nameplural`)) + ": ", 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (el) => {
        return (_openBlock(), _createElementBlock("li", {
          key: el.id
        }, [
          _createVNode(_component_router_link, {
            to: _ctx.getLink(el)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getDescription(el)), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: _ctx.getLink(_ctx.fieldNames) + '?' + _ctx.selfType + '_id=' + _ctx.thisId
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.targetType}.newname`)), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}