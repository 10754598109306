
import {computed, defineComponent, onMounted, ref} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {Difference as differenceType } from "@/types";
import {getDigsites, getSectors, saveDigsite, saveSector, getPositions, savePosition, getDynamic, saveDynamic} from "@/api/requests";
import {formatDateTime, parseDate} from "@/utils";
import DifferenceBlock from "@/components/sync/DifferenceBlock.vue";
import { Digsite, Sector, Position, BuildFinding, FoundObject, Sample, Probe, Core, MeasurementData } from "@/types.gen";

interface Errors {
  digsites: differenceType[];
  sectors: differenceType[];
  positions: differenceType[];
  buildfindings: differenceType[],
  foundobjects: differenceType[],
  samples: differenceType[],
  probes: differenceType[],
  cores: differenceType[],
  measurementdatas: differenceType[],
}

interface syncQueue {
  digsites: Digsite[],
  sectors: Sector[],
  positions: Position[],
  buildfindings: BuildFinding[],
  foundobjects: FoundObject[],
  samples: Sample[],
  probes: Probe[],
  cores: Core[],
  measurementdatas: MeasurementData[],
}

export default defineComponent({
  components: {DifferenceBlock },
  setup() {
    const store = useStore()
    const router = useRouter()

    const localChanges = computed(() => store.getters['sync/queueLength'])
    const queue = computed<syncQueue>(() => store.getters['sync/queue']);
    const lasySync = computed(() => store.getters['sync/lastSync']);

    const activeId = ref(0)

    onMounted(() => {
      onFetch()
    })

    const differences = ref<Errors>({
        digsites: [],
        sectors: [],
        positions: [],
        buildfindings: [],
        foundobjects: [],
        samples: [],
        probes: [],
        cores: [],
        measurementdatas: [],
    })

    async function onFetch() {
      differences.value = {
        digsites: [],
        sectors: [],
        positions: [],
        buildfindings: [],
        foundobjects: [],
        samples: [],
        probes: [],
        cores: [],
        measurementdatas: [],
      }

      const digsites = await getDigsites()

      if ('error' in digsites) {
        alert('error')
        return;
      }

      const sectors = await getSectors()
      if ('error' in sectors) {
        alert('error')
        return;
      }

      const positions = await getPositions()
      if ('error' in positions) {
        alert('error')
        return;
      }

      const buildfindings = await getDynamic<BuildFinding>('buildfindings')
      if ('error' in buildfindings) {
        alert('error')
        return;
      }

      const foundobject = await getDynamic<FoundObject>('foundobject');
      if ('error' in foundobject) {
        alert('error')
        return;
      }

      const Sample = await getDynamic<Sample>('sample');
      if ('error' in Sample) {
        alert('error')
        return;
      }

      const Probe = await getDynamic<Probe>('probe');
      if ('error' in Probe) {
        alert('error')
        return;
      }

      const Core = await getDynamic<Core>('core');
      if ('error' in Core) {
        alert('error')
        return;
      }

      const MeasurementData = await getDynamic<MeasurementData>('measurementdata');
      if ('error' in MeasurementData) {
        alert('error')
        return;
      }

      queue.value.digsites.forEach((digsite : Digsite) => {
        const liveDigsite = digsites.find(d => d.id === digsite.id)

        if (liveDigsite && parseDate(liveDigsite.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.digsites.push({
            local: digsite,
            live: liveDigsite,
            selected: 'none'
          })
        }
        else {
          differences.value.digsites.push({
            local: digsite,
            live: undefined,
            selected: 'none'
          })
        }
      })

      ////// Dies wird die Vorlage!!!!
      queue.value.sectors.forEach((sector : Sector) => {
        const liveSector = sectors.find(d => d.id === sector.id)

        // @ts-ignore
        console.log(new Date(parseDate(liveSector?.altered_at)), store.getters['sync/lastSync'], parseDate(liveSector?.altered_at) > store.getters['sync/lastSync'])
        if (liveSector && parseDate(liveSector.altered_at) > store.getters["sync/lastSync"]) {
          console.log('treuuuuueueuueueuuueue')
          differences.value.sectors.push({
            local: sector,
            live: liveSector,
            selected: 'none'
          })
        }
        else {
          differences.value.sectors.push({
            local: sector,
            live: undefined,
            selected: 'local'
          })
        }
      })
      queue.value.positions.forEach((element : Position) => {
        const liveElement = positions.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.positions.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.positions.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.buildfindings.forEach((element : BuildFinding) => {
        const liveElement = buildfindings.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.buildfindings.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.buildfindings.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.foundobjects.forEach((element : FoundObject) => {
        const liveElement = foundobject.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.foundobjects.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.foundobjects.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.samples.forEach((element : Sample) => {
        const liveElement = Sample.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.samples.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.samples.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.probes.forEach((element : Probe) => {
        const liveElement = Probe.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.probes.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.probes.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.cores.forEach((element : Core) => {
        const liveElement = Core.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.cores.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.cores.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
      queue.value.measurementdatas.forEach((element : MeasurementData) => {
        const liveElement = MeasurementData.find(d => d.id === element.id)

        if (liveElement && parseDate(liveElement.altered_at) > store.getters["sync/lastSync"]) {
          differences.value.measurementdatas.push({
            local: element,
            live: liveElement,
            selected: 'none'
          })
        }
        else {
          differences.value.measurementdatas.push({
            local: element,
            live: undefined,
            selected: 'none'
          })
        }
      })
    }

    function onPull() {
      store.dispatch('sync/sync');
      router.push('/app/home')
    }

    async function onPush() {
      queue.value.digsites.forEach(async (digsite: Digsite) => {
        await saveDigsite(digsite)
      })

      queue.value.sectors.forEach(async (sector: Sector) => {
        await saveSector(sector)
      })

      queue.value.positions.forEach(async (el: Position) => {
        await savePosition(el)
      })

      queue.value.buildfindings.forEach(async (el: BuildFinding) => {
        await saveDynamic('buildfindings', el)
      })

      // TODO: Alle zurück speichern

      onPull()
    }

    const hasDifferences = computed<boolean>(() => {
      return differences.value.digsites.filter(el => el.live).length > 0 ||
              differences.value.sectors.filter(el => el.live).length > 0;
    })

    const hasOpenDifferences = computed<boolean>(() => {
      return differences.value.digsites.find(d => d.selected === 'none') !== undefined ||
          differences.value.sectors.find(d => d.selected === 'none') !== undefined
    })


    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })

    return {
      localChanges,
      onPull,
      onPush,
      queue,
      differences,
      onFetch,
      activeId,
      hasOpenDifferences,
      hasDifferences,
      formatDateTime,
      lasySync,
      isOnline
    };
  },
});
