
import FormContainer from "@/components/forms/FormContainer.vue";
import Modal from "@/components/Modal.vue";
import {useForm} from "@/utils/forms";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import DynamicForm from "@/components/forms/DynamicForm.vue";

export default defineComponent({
  components: {DynamicForm, FormContainer, Modal  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const { data, save } = useForm('Digsite', store, router, route, { digsite_sectors: [] })

    return {
      data,
      save
    };
  },
});
