import {
    format as formatFn,
  } from 'date-fns'

export function parseDate(date: string) {
    return Date.parse(date)
}

export function formatDateTime(d: string): string {
    const date = parseDate(d) - (1000*60*60*2);
    return formatFn(date, 'HH:mm') + ' Uhr am ' + formatFn(date, 'dd.MM.yyyy')
}

export function formatDate(d: string): string {
    return formatFn(parseDate(d), 'dd.MM.yyyy')
}

export function dateToString(d: Date) {
    return formatFn(d, 'yyyy-MM-dd') + 'T' + formatFn(d, 'HH:mm:ss')
}

export function generateUUID(): string {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'cxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0) {
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}