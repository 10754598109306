import { createApp } from 'vue'
import App from './App.vue'
import router from './bootstrap/routes'
import store from './store'

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

import 'bootstrap/scss/bootstrap.scss'
import { setupAxios } from './bootstrap/axios'
import { loadState } from './bootstrap/state'

import { getTranslationValue } from './utils/locales'

loadState()
setupAxios()

const vueApp = createApp(App).component('Datepicker', Datepicker).use(store).use(router);

vueApp.mixin({
    methods: {
        $t: (key: string) => {
            return getTranslationValue(key)
        }
    }
})

vueApp.mount('#app')

console.log(process.env.VUE_APP_SERVER)